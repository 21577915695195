import { createRouter, createWebHistory } from 'vue-router';

// Import the HolidayVideo component
import HolidayVideo from './components/pages/holidays/HolidayVideo.vue';

const routes = [
    {
        name: 'HomePage',
        path: '/',
        component: () => import('./components/pages/home/HomePage.vue')
    },
    {
        name: 'ProjectsPage',
        path: '/projects/:expertise',
        component: () => import('./components/pages/projects/ProjectsPage.vue')
    },
    {
        name: 'ProjectDetailPage',
        path: '/project/:id',
        component: () => import('./components/pages/projects/projects_page_components/ProjectDetailPage.vue'),
        props: true  
    },
    {
        name: 'PeopleDetailPage',
        path: '/people/:id',
        component: () => import('./components/pages/people/people_page_components/PeopleDetailPage.vue'),
        props: true
    },
    {
        name: "PeoplePage",
        component: () => import('./components/pages/people/PeoplePage.vue'),
        path: "/people",
    },
    
    {
        name: 'AboutPage',
        path: '/about',
        component: () => import('./components/pages/about/AboutPage.vue')
    },
    {
        path: '/careers',
        name: 'CareersPage',
        component: () => import('./components/pages/careers/CareersPage.vue'),
        props: true  
    },
    {
        path: '/career/:id',
        name: 'CareerDetailsPage',
        component: () => import('./components/pages/careers/careers_page_components/CareersDetailPage.vue'),
        props: true  
    },
    {
        name: 'EmployeesPage',
        path: '/employees',
        component: () => import('./components/pages/employees/EmployeesPage.vue')
    },
    {
        name: 'ExpertisePage',
        path: '/expertise',
        component: () => import('./components/pages/expertise/ExpertisePage.vue')
    },
    {
        name: 'OfficesPage',
        path: '/offices',
        component: () => import('./components/pages/offices/OfficesPage.vue')
    },
    {
        name: 'GolfPage',
        path: '/golf',
        component: () => import('./components/pages/golf/GolfPage.vue')
    },
    {
        path: '/holidays',
        name: 'HolidayVideo',
        component: HolidayVideo
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})
export default router;