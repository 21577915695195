<template>
  <Navbar />
  <div class="holiday-container">
    <!-- Header with "Happy Holidays" and Company Logo -->
    <div class="header">
      <h1>Happy Holidays!</h1>
      <p>
        Wishing you a joyful and peaceful holiday season filled with warmth, happiness, and cherished moments.
        As we reflect on the year gone by, we are deeply grateful for your continued trust and support.
        May the coming year bring you success, health, and fulfillment.
      </p>
    </div>

    <div class="video-wrapper">
      <div class="video-container">
        <iframe
          src="https://player.vimeo.com/video/1041763568?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          style="position:absolute;top:0;left:0;width:100%;height:100%;"
          title="Happy Holidays from MPENGS">
        </iframe>
      </div>
    </div>

    <div class="message">
      <p>
        Enjoy this special holiday video as a token of our appreciation.
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HolidayVideo',
  components: {
  },
  data() {
    return {
      showVideo: false,
    };
  },
  mounted() {
    // Enable Christmas overlay script
    const script = document.createElement('script');
    script.src = '/ChristmasOverlay.js';
    script.onload = () => {
      if (window.christmasOverlaySnow) {
        window.christmasOverlaySnow.enable();
      }
    };
    document.body.appendChild(script);
  },
  metaInfo() {
    return {
      title: 'Happy Holidays Video 2024',
      meta: [
        { name: 'description', content: 'Happy Holidays from MPENGS 2024!' },
        { name: 'keywords', content: 'holidays, video, celebration, 2024' },
        { property: 'og:title', content: 'Happy Holidays!' },
        { property: 'og:description', content: 'Holiday greetings from MPENGS 2024!' },
        { property: 'og:image', content: '@/assets/MP-logo.png' },
      ],
    };
  },
};
</script>

<style scoped>
.holiday-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  overflow: hidden;
  background-color: #ffffff;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
  max-width: 1200px;
  text-align: center;
  flex-wrap: wrap;
}

.header h1 {
  font-size: 2rem;
  color: #084d80;
}

.header p {
  color: #084d80;
}

.video-wrapper {
  position: relative;
  width: 50%;
  max-width: 1200px;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}

.poster-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.message {
  width: 50%;
  max-width: 1200px;
  padding: 0 20px;
  text-align: center;
  margin-top: 20px;
  color: #084d80;
}

@media (max-width: 768px) {
  .header {
    width: 95%;
  }

  .header h1 {
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }

  .header p {
    font-size: 0.9rem;
  }

  .video-wrapper {
    width: 95%;
  }

  .message {
    width: 95%;
    font-size: 0.9rem;
  }
}
</style>

<style>
html, body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}
</style>